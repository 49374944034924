import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import "./plugins/vant.js";
import "./js/iconfont";
// import axios from "axios";
/* import ElementUI from "element-ui";

Vue.use(ElementUI); */
// Vue.prototype.axios = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
/* router.afterEach(() => {
  window.scrollTo(0, 0);
}); */
