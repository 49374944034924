<template>
  <div class="FootPc">
    <!-- <div class="before"></div> -->
    <!-- <img class="befor" width="100%" src="../assets/矩形 2.png" alt="" /> -->
    <div class="footpc">
      <div class="left">
        <div class="font" style="cursor: pointer">
          <router-link to="/">HONG&nbsp;KONG&nbsp;TRUST</router-link>
        </div>
        <!-- <div>
        <router-link to="/about?maodian=OurBackground"
          >Our Background</router-link
        >
      </div>
      <div>
        <router-link to="/about?maodian=OurAdvantages"
          >Our Advantages</router-link
        >
      </div> -->
      </div>
      <div class="center">
        <div class="minletf">
          <div class="font" style="cursor: pointer">TRUST SERVICE CATEGORY</div>
          <div class="minletfs">
            <div class="lefts">
              <div @click="hanleTo('SavingsTrust')" style="cursor: pointer">
                Inheritance Savings Trust
              </div>
              <div @click="hanleTo('FamilyTrust')" style="cursor: pointer">
                Family Trust
              </div>
              <div @click="hanleTo('RealEstateTrust')" style="cursor: pointer">
                Real Estate Trust
              </div>
              <div
                @click="hanleTo('Insurance+SavingsTrust')"
                style="cursor: pointer"
              >
                Insurance Trust
              </div>
            </div>
            <div class="rights">
              <div @click="hanleTo('CharityTrust')" style="cursor: pointer">
                Charity Trust
              </div>
              <div @click="hanleTo('MigrationTrust')" style="cursor: pointer">
                Migration Trust
              </div>
              <div @click="hanleTo('EducationTrust')" style="cursor: pointer">
                Education Trust
              </div>
              <div @click="hanleTo('SpecialKidsTrust')" style="cursor: pointer">
                Special Kids Trust
              </div>
            </div>
          </div>
        </div>
        <div class="mincenter">
          <div class="font" style="cursor: pointer">INSIGHTS</div>
          <router-link to="/FeaturedInsights"
            ><div style="cursor: pointer">Featured Insight</div></router-link
          >
          <router-link to="/RecommendationColumn"
            ><div>
              <div style="cursor: pointer">Recommendation Column</div>
            </div></router-link
          >
          <router-link to="/MarketInformation"
            ><div style="cursor: pointer">Market Information</div></router-link
          >
        </div>
        <div class="minright">
          <div @click="handleClick" class="font" style="cursor: pointer">
            CONTACT US
          </div>
        </div>
      </div>
      <div class="right">
        <div><img width="100%" src="../assets/logo.png" alt="" /></div>
      </div>
    </div>
    <div class="Copyright" style="cursor: pointer">
      Copyright © 2021 Hong Kong Trust Capital Management Limited . All Rights
      Reserved
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    hanleTo(data) {
      this.$router.push(`/`);
      setTimeout(() => {
        this.$router.push(`/Categor?id=${data}`);
      }, 100);
    },
    handleClick() {
      this.drawer = true;
      /* console.log(111); */
      this.$emit("childByValue", this.drawer);
      /* console.log(this.drawer); */
    },
  },
};
</script>
<style lang="scss" scoped>
/* .befor {
  width: 100vw;
  position: absolute;
  top: 0px;
  height: 470px;
}
.before {
  width: 100vw;
  height: 648px;
  background: #000;
  opacity: 0.9;
  position: absolute;
  bottom: 0px;
  z-index: 0;
} */
.FootPc {
  position: relative;
  .Copyright {
    color: rgba(255, 255, 255, 0.604);
    position: absolute;
    bottom: 20px;
    left: 12vw;
  }
}
.footpc {
  position: relative;
  width: 100vw;
  color: rgba(255, 255, 255, 0.604);
  display: flex;
  justify-content: center;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 100px;
  background-image: url("../assets/foot.jpg");
  a {
    color: rgba(255, 255, 255, 0.604);
  }
  a:hover {
    color: #fff;
  }
  .font {
    font-size: 18px;
    font-weight: 500;
  }
  .left {
    // width: 10vw;
    border-top: 2px solid #fff;
    z-index: 1;
    div {
      margin-top: 20px;
    }
    margin-right: 2vw;
  }
  .center {
    width: 50vw;
    display: flex;
    z-index: 1;
    justify-content: space-between;
    .minletf {
      width: 45%;
      border-top: 2px solid #fff;
      div {
        margin-top: 20px;
      }
      .minletfs {
        display: flex;
        margin-top: 0px;
        justify-content: space-between;
        .lefts {
          margin-top: 0px;
          div:hover {
            color: #fff;
          }
        }
        .rights {
          margin-top: 0px;
          div:hover {
            color: #fff;
          }
        }
      }
    }
    .mincenter {
      width: 26%;
      border-top: 2px solid #fff;
      /* text-align: center; */
      div {
        margin-top: 20px;
      }
    }
    .minright {
      width: 20%;
      border-top: 2px solid #fff;
      div {
        margin-top: 20px;
      }
      div:hover {
        color: #fff;
      }
    }
  }
  .right {
    z-index: 1;
    width: 10vw;
    border-top: 2px solid #fff;
    margin-left: 2vw;
    div {
      margin-top: 20px;
      text-align: center;
    }
  }
}
#app .drawer {
  padding: 0px 2% 0px 15%;
  a {
    border-bottom: 1px solid #a95228;
    color: #a95228;
  }
  h1 {
    font-size: 55px;
    margin-top: 0px;
  }
  p {
    font-size: 20px;
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footpc .center {
    width: 60vw;
  }
  #app .drawer {
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
    div {
      font-size: 16px;
    }
  }
}
@media (max-width: 767px) {
  .FootPc {
    display: none;
  }
}
</style>
