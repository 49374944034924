import Vue from "vue";
import VueRouter from "vue-router";
//import Hktcn from "../views/Hktcm.vue";

Vue.use(VueRouter);

const routes = [
  /* {
    path: "/",
    name: "Hktcn",
    component: Hktcn,
  }, */

  {
    path: "/",
    name: "HongKongTrust",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HongKongTrust.vue"),
  }, //默认首页
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/m_About",
    name: "m_About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "m_About" */ "../views/min/m_About.vue"),
  },
  {
    path: "/HongKongTrust",
    name: "HongKongTrust",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "HongKongTrust" */ "../views/HongKongTrust.vue"
      ),
  },
  {
    path: "/WealthPlanning",
    name: "WealthPlanning",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "WealthPlanning" */ "../views/WealthPlanning.vue"
      ),
  },
  {
    path: "/Insights",
    name: "Insights",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Insights" */ "../views/Insights.vue"),
  },
  {
    path: "/SwissTrust",
    name: "SwissTrust",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "SwissTrust" */ "../views/SwissTrust.vue"),
  },
  {
    path: "/TrustServices",
    name: "TrustServices",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "TrustServices" */ "../views/TrustServices.vue"
      ),
  },
  {
    path: "/TrustServiceCategory",
    name: "TrustServiceCategor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "TrustServiceCategor" */ "../views/TrustServiceCategory.vue"
      ),
  },
  {
    path: "/Videos",
    name: "Videos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Videos" */ "../views/Videos.vue"),
  },
  {
    path: "/FeaturedInsights",
    name: "FeaturedInsights",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "FeaturedInsights" */ "../views/FeaturedInsights.vue"
      ),
  },
  {
    path: "/RecommendationColumn",
    name: "RecommendationColumn",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "RecommendationColumn" */ "../views/RecommendationColumn.vue"
      ),
  },
  {
    path: "/MarketInformation",
    name: "MarketInformation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MarketInformation" */ "../views/MarketInformation.vue"
      ),
  },
  {
    path: "/Article",
    name: "Article",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Article" */ "../views/Article.vue"),
  },
  {
    path: "/Categor",
    name: "Categor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Categor" */ "../views/Categor.vue"),
  },
];
const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    /* console.log(to, 9);
    console.log(from, 8);
    console.log(savedPosition, 7);
    console.log(to.hash, 1); */
    if (to.hash === "") {
      return { x: 0, y: 0 };
    } else {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});
export default router;
