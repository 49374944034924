<template>
  <div id="app">
    <div class="pc" id="haner">
      <!-- <heard-pc
        v-show="Isshow"
        @scrollTop="scrollTop"
        @childByValue="childByValue"
      ></heard-pc> -->
      <heard-pc-background @childByValue="childByValue"></heard-pc-background
      ><!-- v-show="!Isshow" -->
    </div>
    <!-- 手机导航 -->
    <van-sticky :offset-top="50">
      <div class="min" @click="minheard" v-show="!minshow">
        <img height="54px" src="../src/assets/bluelogo.png" alt="" />
        <svg
          class="icon menu"
          aria-hidden="true"
          style="font-size: 30px; color: #093f8d"
        >
          <use se xlink:href="#icon-caidan"></use>
        </svg>
      </div>
    </van-sticky>

    <div class="minheard" v-show="minshow" @click="minheard">
      <!-- <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        height="100vh"
        @click-nav="handleindex(activeIndex)"
      /> -->
      <van-sidebar v-model="activeIndex">
        <van-sidebar-item
          v-for="(item, index) in items"
          :key="index"
          :title="item.text"
          @click="hanldeto(index)"
        />
      </van-sidebar>
      <!--:to="`/${router[index]}`"  -->
    </div>
    <el-dialog :visible.sync="dialogFormVisible" width="90%" top="15vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="Name" prop="username">
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item label="Phone" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Message" prop="desc">
          <el-input type="textarea" v-model="ruleForm.leave_content"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <router-view @childByValue="childByValue" />
    <footmin v-if="IsShow"></footmin>
    <foot-pc v-show="Ishome" @childByValue="childByValue"></foot-pc>
    <el-drawer
      size="40%"
      :visible.sync="drawer"
      :before-close="handleClose"
      style="color: #000"
    >
      <div id="drawer" class="drawer">
        <h1 style="font-weight: 500">Contact Us</h1>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="105px"
          label-position="left"
          class="demo-ruleForm"
        >
          <el-form-item label="Name" prop="username">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="Phone" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item label="Message" prop="desc">
            <el-input
              type="textarea"
              v-model="ruleForm.leave_content"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >Submit</el-button
            >
          </el-form-item>
        </el-form>
        <h2 style="font-size: 30px; font-weight: 500">
          HONG KONG TRUST CAPITAL MANAGEMENT LTD
        </h2>
        <p>
          Tel&nbsp;&nbsp;&nbsp; : <span class="family">+852 2111 2611</span>
        </p>
        <p>Mail：<span class="family">info@hongkongtrust.com.hk</span></p>
        <p>
          Add ：<span class="family"
            >Rm 1218B-1219B, 12/F, Tower 3, China Hong Kong City,</span
          >
        </p>
        <p style="text-indent: 3.6rem">
          <span class="family">33 Canton Road, Tsim Sha Tsui, Kowloon</span>
        </p>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.4044275935994!2d114.1655961153458!3d22.30053954852068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ed2c5caca9%3A0xbd77b0fac080c1e!2sChina%20Hong%20Kong%20City!5e0!3m2!1sen!2shk!4v1631517309025!5m2!1sen!2shk"
            width="100%"
            height="400"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
/* import HeardPc from "./components/HeardPc.vue"; */
import Footmin from "./components/Footmin.vue";
import HeardPcBackground from "./components/HeardPcBackground.vue";
import FootPc from "./components/FootPc.vue";
import axios from "axios";
import qs from "qs";
export default {
  components: {
    /*     HeardPc, */
    Footmin,
    HeardPcBackground,
    FootPc,
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("Please your email"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("Please enter the correct email address"));
        }
      }, 100);
    };
    return {
      dialogFormVisible: false,
      ruleForm: {
        username: undefined,
        email: undefined,
        phone: undefined,
        leave_content: undefined,
      },
      rules: {
        username: [
          { required: true, message: "Please your name", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "Please your phone", trigger: "blur" },
        ],
        email: [{ required: true, validator: checkEmail, trigger: "blur" }],
        desc: [{ required: false, message: "", trigger: "blur" }],
      },
      scrollTop: 0,
      Isshow: true,
      IsShow: true,
      drawer: false,
      Ishome: true,
      items: [
        // { text: "Home" },
        { text: "Hong Kong Trust" },
        { text: "Swiss Trust" },
        { text: "Trust Services" },
        { text: "Trust Service Category" },
        { text: "Wealth Planning" },
        { text: "Insights" },
        { text: "Featured Insights" },
        { text: "Recommendation" },
        { text: "Market Information" },
        /* { text: "Videos" }, */
        { text: "Contact Us" },
        { text: "Client Login" },
      ],
      router: [
        // "Home",
        "HongKongTrust",
        "SwissTrust",
        "TrustServices",
        "TrustServiceCategor",
        "WealthPlanning",
        "Insights",
        "FeaturedInsights",
        "RecommendationColumn",
        "MarketInformation",
      ],
      activeId: 1,
      activeIndex: 0,
      active: 0,
      minshow: false,
      to: "",
    };
  },
  methods: {
    /* _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }, */
    hanldeto(index) {
      switch (index) {
        case 0:
          this.$router.push("/");
          break;
        // case 1:
        //   this.$router.push("/HongKongTrust");
        //   break;
        case 1:
          this.$router.push("/SwissTrust");
          break;
        case 2:
          this.$router.push("/TrustServices");
          break;
        case 3:
          this.$router.push("/TrustServiceCategory");
          break;
        case 4:
          this.$router.push("/WealthPlanning");
          break;
        case 5:
          this.$router.push("/Insights");
          break;
        case 6:
          this.$router.push("/FeaturedInsights");
          break;
        case 7:
          this.$router.push("/RecommendationColumn");
          break;
        case 8:
          this.$router.push("/MarketInformation");
          break;
        case 9:
          this.dialogFormVisible = true;
          break;
        case 10:
          window.open(
            "https://member.hongkongtrust.com.hk"
          );
          break;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.to = qs.stringify(this.ruleForm);
          /* console.log(this.to); */
          axios
            .post("https://www.hongkongtrust.com.hk/email.php", this.to)
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                this.$message({
                  message: "Submitted successfully",
                  type: "success",
                });
                this.ruleForm = {};
              } else {
                this.$message({
                  message: "Submitted failure",
                  type: "error",
                });
              }
            });
          this.dialogFormVisible = false;
        } else {
          /* console.log("error submit!!"); */
          return false;
        }
      });
    },
    childByValue(childByValue) {
      this.drawer = childByValue;
      /* console.log(this.drawer); */
    },
    handleClose() {
      this.drawer = false;
    },
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop === 0 ? (this.Isshow = true) : (this.Isshow = false);
      /* console.log(this.scrollTop); */
    },
    minheard() {
      this.minshow = !this.minshow;
    },
    handleindex(index) {
      /* console.log(index, 1222); */
      /* this.minshow = false; */
      switch (index) {
        case 0:
          this.$router.push("/");
          break;
        case 1:
          this.$router.push("/HongKongTrust");
          break;
        case 2:
          this.$router.push("/SwissTrust");
          break;
        case 3:
          this.$router.push("/TrustServices");
          break;
        case 4:
          this.$router.push("/TrustServiceCategory");
          break;
        case 5:
          this.$router.push("/WealthPlanning");
          break;
        case 6:
          this.$router.push("/Insights");
          break;
        case 7:
          this.$router.push("/FeaturedInsights");
          break;
        case 8:
          this.$router.push("/RecommendationColumn");
          break;
        case 9:
          this.$router.push("/MarketInformation");
          break;
        case 10:
          this.$router.push("/Videos");
          break;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // if (this._isMobile()) {
    //   console.log(this.$route);
    //   this.$router.replace("/m_About");
    //   //alert("手机端");
    // } else {
    //   this.$router.replace("/");
    //   //alert("pc端");
    // }
    /* document.body.style.overflowX = "hidden"; */
  },
  updated() {
    switch (this.$route.path) {
      case "/Categor":
        this.Isshow = false;
        break;
      /* case "/":
        this.IsShow = false;
        break; */
      default:
        this.IsShow = true;
    }
    /* this.$route.path === "/" ? (this.Ishome = false) : (this.Ishome = true); */
    /* console.log(this.$route.path, 1); */
  },
  created() {},
};
</script>
<style lang="scss">
@import url("./assets/font/font.css");
@import url("./assets/font/Contentfont.css");
#app {
  .family {
    font-family: contert;
    /* font-size: 16px; */
  }
  .el-drawer__header {
    margin: 0px;
    padding-top: 10px;
  }
  .el-form {
    .el-form-item__label {
      font-size: 20px;
    }
  }
  .el-button {
    font-size: 20px;
    font-family: contert;
  }
  .drawer {
    text-align: left;
    padding: 0px 5% 0px 10%;
    a {
      border-bottom: 1px solid #a95228;
      color: #a95228;
    }
    h1 {
      font-size: 36px;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    img {
      transition: all 0.6s;
      cursor: pointer;
    }
    h2 {
      font-size: 24px;
      margin: 0px;
    }
    p {
      font-size: 20px;
      margin: 5px 0;
    }
  }
}
body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
.footpc {
  margin-top: 100px;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
// @font-face {
//   font-family: "webfont";
//   font-display: swap;
//   src: url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900|Noto+Serif+SC:200,300,400,500,600,700,900"); /* IE9*/
//   src: url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900|Noto+Serif+SC:200,300,400,500,600,700,900")
//       format("embedded-opentype"),
//     /* IE6-IE8 */
//       url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900|Noto+Serif+SC:200,300,400,500,600,700,900")
//       format("woff2"),
//     url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900|Noto+Serif+SC:200,300,400,500,600,700,900")
//       format("woff"),
//     /* chrome、firefox */ url("//at.alicdn.com/t/webfont_vv5j8k30h2.ttf")
//       format("truetype"),
//     /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//       url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900|Noto+Serif+SC:200,300,400,500,600,700,900")
//       format("svg"); /* iOS 4.1- */
// }
@font-face {
  font-family: "webfont";
  src: url("./assets/font/Merriweather/Merriweather-Light-7.ttf");
}
@font-face {
  font-family: "webfont2";
  src: url("./assets/font/Merriweather/Merriweather-Regular-9.ttf");
}
@font-face {
  font-family: "Merriweather";
  src: url("./assets/font/Merriweather/Merriweather-Light-7.ttf")
    format("woff2");
  src: url("./assets/font/Merriweather/Merriweather-LightItalic-8.ttf")
    format("woff2");
  src: url("./assets/font/Merriweather/Merriweather-Regular-9.ttf")
    format("woff2");
}
a {
  text-decoration: none;
}
#app {
  font-family: "Georgia";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  line-height: 1.5;
  .h1 {
    font-family: "FZZJ";
  }
  h2 {
    font-weight: 500;
  }
}
//手机端导航栏样式
.van-sidebar-item--select::before {
  width: 6px !important;
  height: 60px !important;
  background-color: #093f8d !important;
}
a {
  color: #000;
}
#nav {
  padding: 30px;

  a {
    font-weight: 500;
    color: #000;

    /* &.router-link-exact-active {
      color: #42b983;
    } */
  }
}
.min {
  display: none;
}
.minheard {
  position: fixed;
  z-index: 22;
  width: 100vw;
  height: 100vh;
  top: 0px;
  background-color: #00000078;
}
#app {
  .van-tree-select {
    width: 30vw;
    z-index: 9999;
  }
  .van-tree-select__content {
    display: none;
  }
  .van-tree-select__nav {
    width: 30vw;
  }
}
.footmin {
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  #app {
    overflow-x: hidden;
    .van-sidebar {
      width: 30vw;
      height: 100%;
      background: #f7f8fa;
    }
  }
  .HeardPc {
    display: none;
  }
  .HeardPcBackground {
    display: none;
  }
  .banner {
    margin-top: 64px;
  }
  .min {
    display: flex;
    text-align: right;
    position: fixed;
    width: 100vw;
    z-index: 666;
    padding: 4px 0px;
    top: 0px;
    background: #ffffff;
    align-items: center;
    justify-content: space-between;
    .menu {
      position: relative;
      right: 10px;
    }
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .HeardPc {
    display: none;
  }
  .HeardPcBackground {
    display: none;
  }
  .min {
    display: flex;
    text-align: right;
    position: fixed;
    width: 100vw;
    z-index: 666;
    padding: 4px 0px;
    top: 0px;
    background: #ffffff;
    align-items: center;
    justify-content: space-between;
    -webkit-overflow-scrolling: touch;
    .menu {
      position: relative;
      right: 10px;
    }
  }
  #app {
    /* .banner img {
      height: 520px;
      -o-object-fit: cover;
      object-fit: cover;
    } */
    .van-sidebar {
      width: 45vw;
      height: 100%;
      background: #f7f8fa;
    }
    .el-dialog__headerbtn {
      top: 10px;
      right: 15px;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    .el-dialog__header {
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .el-dialog__body {
      padding: 30px 10px 8px;
    }
    .van-sidebar-item__text {
      word-wrap: break-word;
      word-break: normal;
    }
    .van-tree-select {
      width: 40vw;
      z-index: 999;
    }
    .van-tree-select__content {
      display: none;
    }
    .van-tree-select__nav {
      width: 40vw;
    }
  }
  .footmin {
    display: block;
    text-align: left;
    width: 100vw;
    background: #000;
    padding: 20px 0;
    // margin-top: 50px;
    p {
      width: 90vw;
      font-size: 14px;
      margin: 0 auto;
      margin-bottom: 10px;
      color: #fff;
    }
  }
}
</style>
