<template>
  <div class="HeardPcBackground" style="height: 81.86px">
    <div class="heard">
      <div class="logo">
        <router-link to="/"
          ><img src="../assets/bluelogo.png" alt=""
        /></router-link>
      </div>
      <div class="link">
        <div>
          <ul
            style="
              list-style-type: none;
              display: flex;
              justify-content: flex-end;
            "
          >
            <li style="line-height: 1.5">
              <button
                class="Label"
                style="background-color: #093f8d; border: #093f8d"
              >
                <a
                  style="color: #fff; font-size: 13px"
                  href="https://member.hongkongtrust.com.hk"
                >
                  <van-icon name="user-o" />Client Login</a
                >
              </button>
            </li>
          </ul>
        </div>
        <div class="navbar-nav">
          <ul>
            <!-- <li>
              <router-link to="/">Home</router-link>
            </li> -->
            <li>
              <router-link to="/HongKongTrust">Hong Kong Trust</router-link>
              <ul class="child">
                <li>
                  <router-link to="/HongKongTrust#FiveCoreAdvantages"
                    >Five Core Advantages</router-link
                  >
                </li>
                <li>
                  <router-link to="/HongKongTrust#FeaturesofHongKongTrustLaw"
                    >Features of Hong Kong Trust Law</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/SwissTrust">Swiss Trust</router-link>
              <ul class="child2">
                <li>
                  <router-link to="/SwissTrust#CoreAdvantages"
                    >Advantages</router-link
                  >
                </li>
                <li>
                  <router-link to="/SwissTrust#FeaturesofSwissTrust"
                    >Features of Swiss Trust</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/TrustServices">Trust Services</router-link>
            </li>
            <li>
              <router-link to="/TrustServiceCategory"
                >Trust Service Category</router-link
              >
            </li>
            <li>
              <router-link to="/WealthPlanning">Wealth Planning</router-link>
              <ul class="child">
                <li>
                  <router-link to="/WealthPlanning#ACustomizedTrust"
                    >A Customized Trust</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/Insights">Insights</router-link>
              <!-- Insights
            <svg style="font-size: 16px" class="icon" aria-hidden="true">
              <use xlink:href="#icon-down"></use>
            </svg> -->
              <ul class="child3">
                <li>
                  <router-link to="/FeaturedInsights"
                    >Featured Insights</router-link
                  >
                </li>
                <li>
                  <router-link to="/RecommendationColumn"
                    >Recommendation</router-link
                  >
                </li>
                <li>
                  <router-link to="/MarketInformation"
                    >Market Information</router-link
                  >
                </li>
                <!-- <li>
                <router-link to="/Videos">Videos</router-link>
              </li> -->
              </ul>
            </li>
            <li>
              <a href="#" @click="handleClick">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <el-drawer
      size="40%"
      :visible.sync="drawer"
      :before-close="handleClose"
      style="color: #000"
    >
      <div id="drawer" class="drawer">
        <h1>Contact Us</h1>
        <h2>HONG KONG TRUST CAPITAL MANAGEMENT LTD</h2>
        <p>TEL: +852 2111 2611</p>
        <p>Mail：info@hongkongtrust.com.hk</p>
        <p>Add：Rm 1218B-1219B, 12/F, Tower 3, China Hong Kong City,</p>
        <p style="text-indent: 3.6rem">
          33 Canton Road, Tsim Sha Tsui, Kowloon
        </p>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.4044275935994!2d114.1655961153458!3d22.30053954852068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ed2c5caca9%3A0xbd77b0fac080c1e!2sChina%20Hong%20Kong%20City!5e0!3m2!1sen!2shk!4v1631517309025!5m2!1sen!2shk"
            width="100%"
            height="400"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </el-drawer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollTop: 0,
      drawer: false,
    };
  },
  mounted() {
    /* window.addEventListener('scroll', this.handleScroll)
    console.log(scroll); */
  },
  methods: {
    handleClick() {
      this.drawer = true;
      this.$emit("childByValue", this.drawer);
    },
    /* handleClose() {
      this.drawer = false;
    }, */
    /* handleScroll () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop */
    /* var currentVal = scrollTop - this.oldScrollTop
      this.oldScrollTop = scrollTop */
    /* console.log(this.scrollTop);
      this.scrollTop === 0?document.getElementById('heard').backgroundColor = '':document.getElementById('heard').backgroundColor = '#fff' */
    /* if(currentVal < 0){
        console.log(1);
      }else{
        console.log(2);
      } */
    //}
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.heard .link .Label a:hover {
  color: rgb(255, 255, 255);
}
.heard {
  z-index: 666;
  width: 100vw;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  .logo {
    width: 10vw;
    img {
      width: 100%;
    }
  }
  .link {
    ul {
      list-style-type: none;
      display: flex;
      align-items: flex-end;
      li {
        color: #000;
        margin-left: 40px;
        position: relative;
        line-height: 40px;
        a {
          color: #000;
        }
        a:hover {
          color: #093f8d;
          text-decoration: underline;
        }
        .child {
          position: absolute;
          flex-direction: column;
          padding-left: 0px;
          display: none;
          background-color: #fff;
          width: 310px;
          /* padding: 5px; */
          /* padding-left: 40px; */
          left: -95px;
          li {
            margin-left: 0px;
            margin-top: 20px;
          }
        }
        .child2 {
          position: absolute;
          flex-direction: column;
          padding-left: 0px;
          display: none;
          background-color: #fff;
          width: 310px;
          /* padding: 5px; */
          /* padding-left: 40px; */
          left: -115px;
          li {
            margin-left: 0px;
            margin-top: 20px;
          }
        }
        .child3 {
          position: absolute;
          flex-direction: column;
          padding-left: 0px;
          display: none;
          background-color: #fff;
          width: 310px;
          /* padding: 5px; */
          /* padding-left: 40px; */
          left: -125px;
          li {
            margin-left: 0px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
.heard .link ul li:hover {
  .child {
    display: block;
  }
  .child2 {
    display: block;
  }
  .child3 {
    display: block;
  }
}

@media (min-width: 1025px) and (max-width: 1500px) {
  .heard .link ul li {
    margin-left: 20px;
    font-size: 14px;
  }
  .heard .log {
    width: 5vw;
  }
}
</style>
